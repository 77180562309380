import axios from 'axios'
import qs from 'qs';
import {SERVER_HOST} from './../common/portConfig'
var instance = axios.create({
    headers: {
        'Authorization': ''
    }
});
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = sessionStorage.getItem('Authorization');
        return config
    }, err => {
        return Promise.reject(err)
    });


// 简介
export const reqGetIntroductionListPage = params => { return instance.get(`${SERVER_HOST}/admin/introduction/list`,{ params: params }) };
export const reqAddIntroduction = params => { return instance.post(`${SERVER_HOST}/admin/introduction/add`, params) };
export const reqEditIntroduction = params => { return instance.post(`${SERVER_HOST}/admin/introduction/edit`, params) };
export const reqDeleteIntroduction= params => { return instance.delete(`${SERVER_HOST}/admin/introduction/delete/`+params.id, params) };
export const reqBatchDeleteIntroduction = params => { return instance.post(`${SERVER_HOST}/admin/introduction/batchdelete/`, params) };
export const reqGetIntroductionOne = params => { return instance.get(`${SERVER_HOST}/admin/introduction/one`, { params: params }) };


// 攻略
export const reqGetStrategyListPage = params => { return instance.get(`${SERVER_HOST}/admin/strategy/list`,{ params: params }) };
export const reqAddStrategy = params => { return instance.post(`${SERVER_HOST}/admin/strategy/add`, params) };
export const reqEditStrategy = params => { return instance.post(`${SERVER_HOST}/admin/strategy/edit`, params) };
export const reqDeleteStrategy= params => { return instance.delete(`${SERVER_HOST}/admin/strategy/delete/`+params.id, params) };
export const reqBatchDeleteStrategy = params => { return instance.post(`${SERVER_HOST}/admin/strategy/batchdelete/`, params) };
export const reqGetStrategyOne = params => { return instance.get(`${SERVER_HOST}/admin/strategy/one`, { params: params }) };
